<template>
  <div v-loading="loading">
    <!-- <nav> -->
    <!--   <router&#45;link to="/">home</router&#45;link> | -->
    <!--   <router&#45;link to="/login">login</router&#45;link> -->
    <!--   <router&#45;link to="/admin/cupon">cupon</router&#45;link> -->
    <!-- </nav> -->
    <router-view />
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'App',
  components: {
    // loading,
  },
  methods: {},
  computed: {
    loading () {
      return store.state.isLoading
    }
  },
  watch: {
    name(newToken) {
      localStorage.token = newToken;
    }
  }
}

</script>

<style>
#app {
}

body {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrapper {
  position: relative;
}
</style>
