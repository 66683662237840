import Vue from 'vue'
import Router from 'vue-router'
import Login from './pages/login.vue'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/admin/cupon',
      name: 'cupon-list',
      component: () => import (/* webpackChunkName: "coupon-list" */ './pages/coupon/coupon-list.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/cupon/new',
      name: 'new-cupon',
      component: () => import (/* webpackChunkName: "coupon-handle" */ './pages/coupon/handle-coupon.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/cupon/:id',
      name: 'edit-cupon',
      component: () => import (/* webpackChunkName: "coupon-handle" */ './pages/coupon/handle-coupon.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/color',
      name: 'color-list',
      component: () => import (/* webpackChunkName: "coupon-list" */ './pages/color/color-list.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/color/new',
      name: 'new-color',
      component: () => import (/* webpackChunkName: "coupon-handle" */ './pages/color/handle-color.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/color/:id',
      name: 'edit-color',
      component: () => import (/* webpackChunkName: "coupon-handle" */ './pages/color/handle-color.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/user/new',
      name: 'new-admin',
      component: () => import (/* webpackChunkName: "coupon-handle" */ './pages/admins/new-admin.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/posts',
      name: 'posts-list',
      component: () => import (/* webpackChunkName: "posts-list" */ './pages/posts/post-list.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/posts/new',
      name: 'new-post',
      component: () => import (/* webpackChunkName: "handle-post" */ './pages/posts/handle-post.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/posts/categories',
      name: 'list-categories',
      component: () => import (/* webpackChunkName: "category-post-list" */ './pages/posts/category-list.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/posts/categories/new',
      name: 'new-category',
      component: () => import (/* webpackChunkName: "handle-category-post" */ './pages/posts/handle-category.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/posts/categories/:id',
      name: 'alter-category',
      component: () => import (/* webpackChunkName: "handle-category-post" */ './pages/posts/handle-category.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/posts/:id',
      name: 'alter-post',
      component: () => import (/* webpackChunkName: "handle-post" */ './pages/posts/handle-post.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/products',
      name: 'products-list',
      component: () => import (/* webpackChunkName: "products-list" */ './pages/products/product-list.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/new',
      name: 'products-new',
      component: () => import (/* webpackChunkName: "products-handle" */ './pages/products/handle-product.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/categories',
      name: 'categories-products-list',
      component: () => import (/* webpackChunkName: "products-categories-list" */ './pages/products/category-list.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/categories/new',
      name: 'categories-products-new',
      component: () => import (/* webpackChunkName: "products-categories-handle" */ './pages/products/handle-category.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/categories/:id',
      name: 'categories-products-alter',
      component: () => import (/* webpackChunkName: "products-categories-handle" */ './pages/products/handle-category.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/subcategories',
      name: 'subcategories-products-list',
      component: () => import (/* webpackChunkName: "products-subcategories-list" */ './pages/products/subcategory-list.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/subcategories/new',
      name: 'subcategories-products-new',
      component: () => import (/* webpackChunkName: "products-subcategories-handle" */ './pages/products/handle-subcategory.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/subcategories/:id',
      name: 'subcategories-products-alter',
      component: () => import (/* webpackChunkName: "products-subcategories-handle" */ './pages/products/handle-subcategory.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/products/:id',
      name: 'alter-products',
      component: () => import (/* webpackChunkName: "products-handle" */ './pages/products/handle-product.vue'),
      meta: { requiresAuth: true }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && localStorage.token) {
    next({
      name: 'cupon-list'
    })
  }
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    // need to login
    if (!localStorage.token) {
      next({
        name: 'login'
      })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

export default router
