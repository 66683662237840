import axios from 'axios'

function getBaseUrl() {
  return process.env.VUE_APP_ROOT_URL
}

export default axios.create({
  baseURL: getBaseUrl()
  // baseURL: 'https://api.maimat.com/'
})
