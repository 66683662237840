<template>
  <div class="tela-login">
    <form v-on:submit.prevent="submit">
      <div class="header">
        <img src="http://maimat.com/temp/themes/aura/img/Logotipo_Maimat_Desktop.png" alt="">
      </div>
      <div>
        <label>usuário:</label>
        <el-input placeholder="e-mail" v-model="email"></el-input>

        <label>senha:</label>
        <el-input type="password" placeholder="senha" v-model="password"></el-input>

        <el-alert
          title="usuário ou senha incorretos"
          type="error"
          v-if="errorlogin"
          >
        </el-alert>

        <el-alert
          :title="errorCustomMessage"
          type="error"
          v-if="errorCustomMessage"
          >
        </el-alert>

        <div class="submit">
          <div>&nbsp;</div>
          <button type="success" plain>login</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'
import api from '@/services/api'

export default {
  name: 'loginpage',
  data: () => {
    return {
      email: null,
      password: null,
      errorlogin: false,
      errorCustomMessage: null,
    };
  },
  computed: {
    token () {
      return store.state.token
    }
  },
  methods: {
    submit(e) {
      e.preventDefault()
      const { email, password } = this

      if (!email || email.length === 0) {
        this.errorCustomMessage = 'Campo e-mail obrigatório, favor preencher'
      }
      else if (!password || password.length === 0) {
        this.errorCustomMessage = 'Campo senha obrigatório, favor preencher'
      }
      else {
        store.commit('setLoading', true)
        api.post(`/backoffice/login`, {email, password})
          .then(res => {
            const { data } = res
            store.commit('setToken', data.token)
            localStorage.token = data.token
            store.commit('setLoading', false)
            this.$router.push('/admin/cupon')
          })
          .catch(() => {
            this.errorCustomMessage = null
            store.commit('setLoading', false)
            this.errorlogin = true
          })
      }

    },
    createNew(e) {
      e.preventDefault()

      if (this.passwordcreate !== this.passwordconfirm) {
        this.errorsignup = true
      }
      else {
        this.errorsignup = false
        store.commit('setLoading', true)

        const { name, email, passwordcreate } = this
        api.post(`/backoffice/user`, {
          name,
          password: passwordcreate,
          email
        })
          .then(() => {
            this.screen = 'login'
            this.$message({
              message: 'Usuário criado com sucesso!',
              type: 'success'
            })
            store.commit('setLoading', false)

          })
          .catch(() => {
            store.commit('setLoading', false)
            this.errorlogin = true
          })
      }
    },
  },
}
</script>

<style scoped>
.tela-login {
  background-color: #edebf7;
  /* background-image: url("https://w.wallhaven.cc/full/4g/wallhaven-4go1d3.jpg"); */
  border: 1px solid #F4F5F8;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tela-login form {
  width: 100%;
  max-width: 450px;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  -webkit-box-shadow: 0 10px 6px -6px #777;
	-moz-box-shadow: 0 10px 6px -6px #777;
	box-shadow: 0 10px 6px -6px #777;
}

.tela-login form img {
  display: block;
  width: 90px;
  margin-bottom: 15px;
  height: auto;
}

.tela-login form label {
  margin-top: 15px;
}

.tela-login form .el-input {
  margin-bottom: 15px;
}

.tela-login form .submit {
  margin-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 4px;
}

.tela-login form .submit button {
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
  border: 1px solid;
  outline: none;
}

.tela-login form .submit button:hover {
  background: #67c23a;
  border-color: #67c23a;
  color: #fff;
  cursor: pointer;
}
</style>
