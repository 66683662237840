import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = {
  state: {
    isLoading: false,
  },
  mutations: {
    setLoading (state, payload) {
      state.isLoading = payload
    }
  }
}

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
  ...store
})
